<template>
<section>
  <!-- 工具条 -->
  <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
  <el-form :inline='true' :model='filter'>
    <el-form-item>
      <el-input @change="hasChange" size='mini' v-model.trim='filter.name' placeholder='关键字搜索'>
        <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
        <el-button slot='append' icon='el-icon-search' @click='getData'></el-button>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
    </el-form-item>
  </el-form>
  </el-col>
   <!-- 列表 -->
   <el-col :span='16' class='tree-list'>
    <el-tree
      :indent='20'
      :data="treelist"
      :props="props"
      node-key="Id"
      :filter-node-method="handleSearch"
      ref='tree'
      default-expand-all
      :expand-on-click-node="false"
      :render-content="handlerRender">
    </el-tree>
  </el-col>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' @close='handlerClose'>
      <el-form :model='formData' label-width='80px' :rules='formRule' ref='form' size='mini'>
        <el-form-item prop='Name' label='组织名称' >
          <el-input @change="hasChange" size='mini' v-model.trim='formData.Name' ></el-input>
        </el-form-item>
        <el-form-item prop='Typ' label='类型' >
          <el-select size='mini' v-model.trim='formData.Typ' clearable placeholder='请选择'>
            <el-option
              v-for="item in options"
              :key="item.K"
              :label="item.V"
              :value="item.K">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop='Pid' label='上一级' >
          <el-cascader size='mini'
            :clearable=true
            :options='treelist'
            v-model.trim='formData.Pid'
            :props='props'
            @change='hasChange'>
          </el-cascader>
        </el-form-item>
      </el-form>
      <div slot='footer'>
        <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='mini' @click.native='handleSubmit' >提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { treeFilter, join, toIntArray } from '@/util/index'

export default {
  data() {
    return {
      changeState: false,
      treelist: null,
      options: [],
      dlg: {
        visible: false,
        title: ''
      },
      filter: {
        name: null,
        field: 'name'
      },
      cascade: [],
      formData: {},
      formRule: {
        name: [{ required: true, message: '名称不可为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(['props'])
  },
  created() {
    this.getData()
    this.$post('admin/listReferParam', { code: 'ORG_TYPE' }).then(res => {
      this.options = res
    }).catch(() => {})
  },
  methods: {
    hasChange() {
      this.changeState = true
    },
    getData: function() {
      this.$post('admin/listCaOrg').then(res => {
        if (res !== null) {
          this.treelist = treeFilter(res)
        }
      })
    },
    // 搜索
    handleSearch(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handlerRender(h, { node, data, store }) {
      return (
        <span>
          <span>{node.label}</span>
          <span>
            <i on-click={ () => this.handleEdit(data) } class='opt el-icon-edit' />
            <i on-click={ () => this.handleDel(node, data) } class='opt el-icon-delete' />
          </span>
        </span>)
    },
    handleRefresh() {
      this.getData()
    },
    handleAdd: function() {
      this.dlg.title = '新增'
      this.dlg.visible = true
      this.formData = {}
      this.cascade = []
    },
    handleEdit: function(row) {
      this.dlg.title = '编辑'
      this.dlg.visible = true
      this.formData = Object.assign({}, row)
      this.cascade = row.Pid == null ? [] : toIntArray(row.Pid)
    },
    handleDel: function(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const para = { id: row.Id }
        this.$get('admin/delCaOrg', para).then(() => {
          this.handleRefresh()
        })
      }).catch(() => {})
    },
    handleSubmit: function() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.formData.Pid = join(this.formData.Pid)
            const para = Object.assign({}, this.formData)
            this.$post('admin/saveCaOrg', para).then(() => {
              this.dlg.visible = false
            })
          }).catch(() => {})
        }
      })
    },
    handlerClose: function() {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    }
  }
}
</script>
